/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Main, Footer, Text } from 'grommet'
import { Keyboard } from 'grommet-icons'
import styled from 'styled-components'

import Header from '../components/header'
import './layout.css'
import BaseLayout from './base-layout'

const StyledMain = styled(Main)`
  min-height: calc(100vh - 44px);
  max-width: 1200px;
  padding: 100px 20px 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
`

const Layout = ({ isHome, children }) => (
  <BaseLayout>
    <Header isHome={isHome} />
    <StyledMain>{children}</StyledMain>
    <Footer background="brand" direction="column" justify="center" pad="medium">
      <Text textAlign="center" size="small">
        Katedra Nanobiotechnologii © 2020-
        {new Date().getFullYear()}
      </Text>
      <Text size="xsmall">
        <Keyboard size="small" /> Konrad Górski
      </Text>
    </Footer>
  </BaseLayout>
)

export default Layout

Layout.propTypes = {
  isHome: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
Layout.defaultProps = { isHome: false }
