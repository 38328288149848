import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'grommet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Layout } from '../layout'
import SEO from '../components/seo'
// import Dump from '../components/Dump'

const IndexWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PostWrapper = styled.article`
  margin-bottom: 80px;
  max-width: 750px;
`

const NewsPage = ({ data }) => (
  <Layout>
    <SEO title="Aktualności" />
    <Heading textAlign="center">Aktualności</Heading>
    {/* <Dump data={data} /> */}
    <IndexWrapper>
      {data.allMdx.nodes.map(({ id, excerpt, fields, frontmatter }) => (
        <PostWrapper key={id}>
          <Link to={fields.slug}>
            {!!frontmatter.cover && (
              <Img fluid={frontmatter.cover.childImageSharp.fluid} />
            )}
            <Heading level={2}>{frontmatter.title}</Heading>
            <p>{frontmatter.date}</p>
            <p>{excerpt}</p>
          </Link>
        </PostWrapper>
      ))}
    </IndexWrapper>
  </Layout>
)

export default NewsPage

NewsPage.propTypes = { data: PropTypes.object.isRequired }

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 200)
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          cover {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 360) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
